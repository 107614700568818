<template>
    <div>
        <h2>Удалить email из стоп-листа</h2>
        <div class="input-wrapper">

            <b-field label="Отправитель">
                <b-input type="email" v-model="remove_data.email_from"></b-input>
            </b-field>

            <div class="or-text">Выберите один из способов указания получателей:</div>

            <b-field label="Получатель" message="Можно указать несколько адресов через запятую">    
                <b-input 
                    type="text" 
                    v-model="remove_data.emails_to_remove"
                    :disabled="!!csvFile"
                ></b-input>
            </b-field>

            <div class="or-text">или</div>

            <b-field label="Загрузить CSV файл с адресами">
                <b-field class="file is-primary"  :class="{'has-name': !!csvFile}">
                    <b-upload v-model="csvFile" class="file-label" :disabled="!!remove_data.emails_to_remove">
                        <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label">Выбрать файл</span>
                        </span>
                        <span class="file-name" v-if="csvFile">
                            {{ csvFile.name }}
                        </span>
                    </b-upload>
                </b-field> 
            </b-field>

            <button 
                type="button" 
                class="button is-primary submit-button"
                :disabled="isLoading || (!remove_data.emails_to_remove && !csvFile) || !remove_data.email_from"
                @click="deleteFromStoplist"
            >
                <span>Удалить</span>
            </button>

        </div>
        <b-loading :is-full-page="true" :active='isLoading'></b-loading>
        <div class="response-block" v-if="response_data.length">
            <div class="response-summary">
                <p>Всего обработано: {{ response_data.length }}</p>
                <p>Успешно: {{ successCount }}</p>
                <p>С ошибками: {{ errorCount }}</p>
            </div>
            <!-- <ul>
                <li v-for='(value, key) in response_data' :key="key" :class="value.status === 'error' ? 'error-item' : 'success-item'">
                    {{value.email}} : {{ value.status }} ({{ value.message }})
                </li>
            </ul> -->
        </div>
        
    </div>
</template>

<script>
    import axios from 'axios'
    export default {

        name: 'DeleteFromStoplist',
        data() {
            return {
                isLoading: false,
                remove_data: {
                    email_from: '',
                    emails_to_remove:'',
                },
                csvFile: null,
                response_data: [],
                authToken: '',
                test_sender_email: /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/,
                test_emails_to_delete: /^((\w+[-\.+]*\w*)+\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,}\s*?,?\s*?)+$/
            }
        },
        computed: {
            successCount() {
                return this.response_data.filter(item => item.status === 'ok').length;
            },
            errorCount() {
                return this.response_data.filter(item => item.status === 'error').length;
            }
        },
        mounted() {
            this.authToken = localStorage.getItem('lh-admin-panel-auth-token');
        },
        methods: {
            handleFileUpload(file) {
                if (file) {
                    this.remove_data.emails_to_remove = '';
                }
            },
            async deleteFromStoplist() {
                if (!this.remove_data.email_from || (!this.remove_data.emails_to_remove && !this.csvFile)) {
                    return this.$message('Заполните все необходимые поля', 'danger');
                }
                
                if (!this.test_sender_email.test(this.remove_data.email_from)) {
                    return this.$message('Некорректный формат email отправителя', 'danger');
                }
                
                this.isLoading = true;
                
                try {
                    let response;
                    
                    if (this.csvFile) {
                        // Проверка расширения файла
                        if (!this.csvFile.name.toLowerCase().endsWith('.csv')) {
                            this.isLoading = false;
                            return this.$message('Неверный формат файла (ожидается CSV)', 'danger');
                        }
                        
                        // Отправка файла
                        const formData = new FormData();
                        formData.append('file', this.csvFile);
                        formData.append('email_from', this.remove_data.email_from);
                        
                        response = await axios.post(
                            `${this.$store.getters.admin_api_url}/tools/remove_email_from_stoplist`, 
                            formData, 
                            {
                                headers: {
                                    'Accept': 'application/json',
                                    'Accept-Language': 'en',
                                    'Content-Type': 'multipart/form-data',
                                    'Auth-Token': this.authToken
                                }
                            }
                        );
                    } else {
                        // Отправка списка адресов
                        if (!this.test_emails_to_delete.test(this.remove_data.emails_to_remove)) {
                            this.isLoading = false;
                            return this.$message('Некорректный формат списка email-адресов', 'danger');
                        }
                        
                        let data = Object.assign({}, this.remove_data);
                        data.emails_to_remove = data.emails_to_remove.split(',').map(el => el.trim());
                        
                        response = await axios.post(
                            `${this.$store.getters.admin_api_url}/tools/remove_email_from_stoplist`, 
                            data, 
                            {
                                headers: {
                                    'Accept': 'application/json',
                                    'Accept-Language': 'en',
                                    'Content-Type': 'application/json',
                                    'Auth-Token': this.authToken
                                }
                            }
                        );
                    }
                    
                    this.response_data = response.data;
                    this.remove_data.email_from = '';
                    this.remove_data.emails_to_remove = '';
                    
                    if (this.response_data.length > 0 && !this.csvFile) {
                        this.$message(`Обработано ${this.response_data.length} адресов`, 'success');
                    } else {
                        this.$message(
                            `Всего обработано адресов: ${this.response_data.length} \n
                            Успешно: ${this.successCount} \n
                            С ошибками: ${this.errorCount}`, 'info');
                    }
                    this.csvFile = null;

                } catch (error) {
                    console.error('Error in remove from stoplist request', error);
                    
                    // Обработка специфических ошибок
                    if (error.response && error.response.status === 400) {
                        const errorMessage = error.response.data.message || '';
                        
                        if (errorMessage.includes('File reading error')) {
                            this.$message('Ошибка чтения файла', 'danger');
                        } else if (errorMessage.includes('Invalid file format')) {
                            this.$message('Неверный формат файла (ожидается CSV)', 'danger');
                        } else if (errorMessage.includes('File or emails_to_remove is missing')) {
                            this.$message('В запросе отсутствует файл или emails_to_remove', 'danger');
                        } else {
                            this.$message(error.response.data.message || 'Ошибка при обработке запроса', 'danger');
                        }
                    } else if (error.response && error.response.data && error.response.data.message) {
                        this.$message(error.response.data.message, 'danger');
                    } else {
                        this.$message('Произошла ошибка при обработке запроса', 'danger');
                    }
                } finally {
                    this.isLoading = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    h2 {
        margin-bottom: 52px;
    }
    .field {
        margin-bottom: 24px !important;
    }
    .input-wrapper {
        width: 30%;
        margin-bottom: 64px;
    }
    
    .or-text {
        margin: 16px 0;
        font-weight: bold;
    }
    
    .submit-button {
        margin-top: 24px;
    }
    
    .response-block {
        position: relative;
        margin-top: 24px;
        min-height: 50%;
        
        .response-summary {
            margin-bottom: 16px;
            padding: 10px;
            background-color: #f5f5f5;
            border-radius: 4px;
        }
        
        li {
            margin-bottom: 16px;
            
            &.success-item {
                color: #48c774;
            }
            
            &.error-item {
                color: #f14668;
            }
        }
    }
    
</style>